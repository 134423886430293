<template>
  <div class="braceletCell">
    <device-msg-head
        :order="order"
        device-type="普通资产"
        :device-mac="z2Info.macAddr"
        :device-station-mac="'基站:' + z2Info.baseMac">
    </device-msg-head>
    <div style="height: 5px"></div>
    <device-msg-cell v-for="(key) in dataList" :key="key" :content-str="key + ':' + dataMsg(key)">
    </device-msg-cell>
    <device-msg-footer :rssi="dataMsg('rssi')" :time-stamp="dataMsg('timestamp')"></device-msg-footer>
    <div style="width: 95%;height: 1px;background-color: #cccccc;margin: 5px 0px 5px 0px"></div>
  </div>
</template>

<script>
import DeviceMsgFooter from '@/views/deviceMQ/deviceMsg/deviceMsgFooter'
import DeviceMsgCell from '@/views/deviceMQ/deviceMsg/deviceMsgCell'
import DeviceMsgHead from '@/views/deviceMQ/deviceMsg/deviceMsgHead'

export default {
  name: 'braceletCell',
  components: { DeviceMsgFooter, DeviceMsgCell, DeviceMsgHead },
  props: {
    order: {
      type: String,
      default: () => {
        return '#'
      }
    },
    braceletInfo: {
      type: Object,
      default: () => {
        return {
          'baseStation': '',
          'battery': '',
          'breath': 0,
          'data': '',
          'dateTime': 0,
          'deviceMac': '',
          'heart': 0,
          'id': '',
          'rssi': 0,
          'status': 0,
          'type': 0
        }
      }
    }
  },
  computed: {
    dataList() {
      return [
        'baseStation',
        'battery',
        'breath',
        'data',
        'dateTime',
        'deviceMac',
        'heart',
        'id',
        'rssi',
        'status',
        'type'
      ]
    }
  },
  methods: {
    dataMsg: function(key) {
      if (typeof this.braceletInfo[key] == 'undefined' || this.braceletInfo[key] == null) {
        return ''
      }
      return this.braceletInfo[key]
    }
  }
}
</script>

<style lang="scss" scoped>

.braceletCell {
  width: 100%;
}

</style>

<template>
  <div class="BraceletView">
    <ul class="infinite-list-wrapper viewList" v-infinite-scroll="updateMsgList" style="overflow:scroll">
      <li v-for="(item, index) in dataList" class="infinite-list-item viewLi" :infinite-scroll-disabled="disabled">
        <bracelet-cell :bracelet-info="item" :order="'#' + (index + 1)"></bracelet-cell>
      </li>
    </ul>
    <div class="msgContent" id="braceletLoading">
      {{ noMore ? '没有更多了' : '' }}
    </div>
  </div>
</template>

<script>

import MQmixin from '@/views/deviceMQ/MQmixin/MQmixin'
import BraceletCell from '@/views/deviceMQ/BraceletView/braceletCell'

export default {
  name: 'BraceletView',
  components: { BraceletCell },
  mixins: [MQmixin],
  data() {
    return {
      viewType:'手环/胸卡',
      loadingDivId: 'braceletLoading',
      urlBlock: (config) => {
        return this.$api.deviceMQ.getBraceletInfoList(config)
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.BraceletView {
  width: calc(100%);
  height: calc(100%);

  .viewList {
    width: calc(100%);
    //height: calc(100% - 40px);
    max-height: calc(100% - 40px);
    list-style: none;
    padding: 0;

    .viewLi {
      width: calc(100%);
      margin-bottom: 5px;
    }
  }

  .msgContent {
    width: calc(100%);

    height: 40px;

    line-height: 40px;

    text-align: center;

    font-size: 15px;

    color: gray;

    margin-top: -10px;

  }
}

</style>
